<template>
  <div>
    <div class="w100" v-if="subsidiary">
      <el-image v-if="subsidiary.thumbnail" class="w100" style="display: block;" :src="subsidiary.thumbnail"></el-image>
    </div>

    <div class="product-select">
      <el-form :inline="true" :model="taxonomy" size="mini">
        <el-form-item :label="$t('page.productCenter.product')">
          <el-select v-model="taxonomy.type">
            <el-option
                v-for="(type, i) in options.productType"
                :label="type[isEn+`name`]"
                :value="type.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('page.productCenter.apps')">
          <el-select v-model="taxonomy.app">
            <el-option
                v-for="(app, i) in options.productApp"
                :label="app[isEn+`name`]"
                :value="app.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">{{$t('search')}}</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-container class="container productCenter">
      <el-aside v-show="subsidiaryType != 'subsidiary'" width="260px" class="product-type">
        <el-card>
          <div slot="header" class="clearfix">{{$t('page.productCenter.product')}}</div>
          <ul class="productCenter-type">
            <!--<li>
              <el-link :underline="false" :class="{'active': 0 == taxonomy.type}" @click="changeType('type', 0)">{{$t('all')}}</el-link>
            </li>-->
            <li v-for="(type, i) in options.productType" :key="`productType`+type.id">
              <el-link style="font-size: 16px;" :underline="false" :class="{'active': type.id == taxonomy.type}" @click="changeType('type', type.id, type[isEn+`name`])">{{type[isEn+`name`]}}</el-link>
              <ul class="children" v-for="(children, index) in type.children"  :key="`productTypeChildren`+children.id">
                <li>
                  <el-link style="font-size: 14px;"  :underline="false" :class="{'active': children.id == taxonomy.type}" @click="changeType('type', children.id, children[isEn+`name`])">{{children[isEn+`name`]}}</el-link>
                </li>
              </ul>
            </li>
          </ul>
        </el-card>

        <el-card>
          <div slot="header" class="clearfix">{{$t('page.productCenter.apps')}}</div>
          <ul class="productCenter-type">
            <!--<li>
              <el-link :underline="false" :class="{'active': 0 == taxonomy.type}" @click="changeType('type', 0)">{{$t('all')}}</el-link>
            </li>-->

            <li v-for="(app, i) in options.productApp" :key="`productApp`+app.id">
              <el-link style="font-size: 16px;"  :underline="false" :class="{'active': app.id == taxonomy.type}" @click="changeType('type', app.id, app[isEn+`name`])">{{app[isEn+`name`]}}</el-link>
              <ul class="children" v-for="(children, index) in app.children"  :key="`productTypeChildren`+children.id">
                <li>
                  <el-link style="font-size: 14px;"  :underline="false" :class="{'active': children.id == taxonomy.type}" @click="changeType('type', children.id, children[isEn+`name`])">{{children[isEn+`name`]}}</el-link>
                </li>
              </ul>
            </li>
          </ul>
        </el-card>
      </el-aside>

      <el-main class="productCenter-main"  v-loading="loading">
        <el-page-header class="mb20" v-if="subsidiary" @back="goBack" :content="$t('page.product.subsidiary') + '-' + subsidiary[isEn+`name`]"></el-page-header>
        <!--<el-tag style="margin-left: 10px" v-if="subsidiary" type="danger">{{$t('page.product.subsidiary')}} : {{subsidiary[isEn+`name`]}}</el-tag>-->

        <div v-if="list.length" class="wrap_content_item">

          <div class="ml10 mb20" >
            <!--<el-radio-group fill="#00923F" v-model="order" @change="getList" size="mini">
              <el-radio-button label="asc">{{$t('page.productCenter.asc')}}</el-radio-button>
              <el-radio-button label="desc">{{$t('page.productCenter.desc')}}</el-radio-button>
            </el-radio-group>

            <el-select class="ml20" v-model="orderType" placeholder="请选择" size="mini" @change="getList">
              <el-option
                  :label="$t('page.productType.create_time')"
                  value="create_time">
              </el-option>
              <el-option
                  :label="$t('page.productType.update_time')"
                  value="update_time">
              </el-option>
            </el-select>-->

            <!--<el-link style="line-height: 30px; margin-left: 10px;" :underline="false" v-show="subsidiaryType != 'subsidiary'">{{text}}</el-link>-->
            <el-link style="line-height: 30px; margin-left: 10px;" :underline="false">{{$t('page.productCenter.product_nums') + '：' + total }}</el-link>
          </div>

          <el-row type="flex" justify="start" class="margin-0 flex-wrap" :gutter="20">
            <el-col
                :xs="12"
                :sm="12"
                :md="8"
                :lg="8"
                :xl="8" v-for="(item, i) in list">
              <div class="wrap_content_item_box" @click="goto(item.id)">
                <img :src="item.images && item.images.length > 0 ? item.images[0]: ''"/>
                <div class="wrap_content_item_box_desc">
                  <p>{{item[isEn+`title`]}}</p>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-pagination
              class="mt20 line"
              :hide-on-single-page="onePage"
              :total="total"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              :page-sizes="pageSizes"
              @size-change="getList"
              @current-change="getList"
              background
              layout="prev, pager, next">
          </el-pagination>
        </div>

        <el-empty v-else :description="$t('empty')"></el-empty>
      </el-main>
    </el-container>


    <el-popover
        placement="left"
        trigger="click"
        width="160"
        style="height: 160px;">
      <div class="wxCode">
        <h3>扫码联系专属客服</h3>
        <p>一对一为您答疑解惑</p>
        <el-image :src="pageOptions.customer_service"></el-image>
        <a>扫码立即联系</a>
        <p style="font-size: 12px">联系电话: 86-769-85613339</p>
      </div>

      <div class="customer-service"  slot="reference">
        <i class="el-icon-service"></i>
        <p>{{$t('page.console.menu.contact_us')}}</p>
      </div>
    </el-popover>
  </div>
</template>

<script>
import {getOption, getListTaxonomy, getTaxonomyAll, getProduct} from '@/api/os/admin';
import config from '@/config';

export default {
  data() {
    return {
      options: {
        productType: [],
        productApp: [],
        subsidiary: [],
        workpiece: []
      },
      taxonomy: {
        type: 11,
        app: ''
      },
      taxonomyName: {
        type: '',
        app: ''
      },
      list: [],
      currentPage: 0,
      pageSize: config.default.pageLimit,
      pageSizes: config.default.pageSizes,
      onePage: false,
      total: 0,
      loading: false,

      subsidiary: '',
      subsidiaryId: 0,
      subsidiaryType: '',

      order: 'desc',
      orderType: 'update_time',
      pageOptions: {}
    }
  },
  async created() {
    this.subsidiaryId = this.$route.query.id
    this.subsidiaryType = this.$route.query.type
    this.options.productType = await this.getTaxonomy({type: 'productType'});
    this.options.productApp = await this.getTaxonomy({type: 'productApp'});
    this.options.subsidiary = await this.getTaxonomy({type: 'subsidiary'});
    this.getSubsidiary(this.subsidiaryId)
    this.getList()
    this.getOptions()
  },
  computed: {
    // add
    isEn() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    },
    text() {
      let num = 0;
      let text = [];

      if(this.taxonomyName.type){
        num++
        text.push(this.taxonomyName.type)
      }

      if(this.taxonomyName.app){
        num++
        text.push(this.taxonomyName.app)
      }

      text = text.join('，')

      return this.$t('page.productCenter.current_taxonomy') + `(${num})：` + text
    }
  },
  methods: {
    getOptions() {
      getOption({
        name: 'page-home',
      }).then(res => {
        if(res.data.data) {
          this.pageOptions = res.data.data
        }
      })
    },
    getSubsidiary(id) {
      let data = this.options.subsidiary.filter(x => x.id == id);
      if(data) {
        this.subsidiary = data[0]
        this.taxonomy.type = ''
        if(this.subsidiaryType !== 'subsidiary') {
          this.taxonomy.type = 11
        }
      }
    },

    getTaxonomy(data) {
      // getListTaxonomy().then(res => {
      //   this.options = res.data.data
      //   if(this.subsidiaryType == 'subsidiary' && this.subsidiaryId) {
      //     this.getSubsidiary(this.subsidiaryId)
      //   }
      // })
      return new Promise(resolve => {
        getTaxonomyAll(data).then(res => {
          let data = res.data.data;
          resolve(data)
        })
      })
    },
    getList() {
      this.loading = true
      let type = [];
      if(this.taxonomy.type) {
        type.push(this.taxonomy.type)
      }
      if(this.taxonomy.app) {
        type.push(this.taxonomy.app)
      }
      if(this.subsidiaryId) {
        type.push(this.subsidiaryId)
      }
      getProduct({
        page: this.currentPage,
        pageSize: this.pageSize,
        type: type.join(','),
        order: this.order,
        orderType: this.orderType,
      }).then(res => {
        this.loading = false
        this.list = res.data.data.list
        this.total = res.data.data.total
        // this.onePage = this.total ? false : true
      })
    },
    changeType(name, id, title = '') {
      if(this.taxonomy[name] == id) {
        this.taxonomy[name] = ''
        this.taxonomyName[name] = ''
      } else {
        this.taxonomy[name] = id
        this.taxonomyName[name] = title
      }
      this.getList()
    },
    goto(id) {
      this.$router.push({
        name: 'productDetail',
        params: {
          id: id
        }
      })
    },
    goBack() {
      this.$router.push({
        name: 'productCenter'
      })
    }
  },
  watch: {
    $route() {
      this.subsidiaryId = this.$route.query.id
      this.subsidiaryType = this.$route.query.type
      this.getSubsidiary(this.subsidiaryId)
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.productCenter{
  margin: 20px auto;
  .productCenter-type{
    li{
      margin: 10px 0;
    }
    .children{
      margin: 10px 0 10px 14px;
      li{
        .el-link{
          font-size: 12px;
        }
      }
    }
  }
  .wrap_content_item_box{
    &:hover{
      background-color: #00923F;
      color: #00923F;
      cursor: pointer;
    }
  }
  .wrap_content_item_box_desc{
    p{
      height: 40px;
      line-height: 20px;

      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.active{
  color: #00923F;
}
.clearfix{
  color: #00923F;
  font-size: 28px;
}
.productCenter-main{
  padding-top: 0 !important;
}
.product-select{
  display: none;
}

.wxCode{
  text-align: center;
  p{
    color: #787878;
    margin: 10px auto;
  }
}
.customer-service{
  position: fixed;
  right: calc( (100% - 1200px) / 2 - 100px);
  bottom: 16%;
  z-index: 99;
  background: #ffffff;
  color: #00923F;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(20, 221, 107, 0.5);
  .el-icon-service{
    font-size: 50px;
  }
}

/* maxWidth <=768 */
@media screen and (max-width: 768px){
  .product-type{
    display: none;
  }
  .productCenter-main{
    padding: 0 !important;
  }
  .product-select{
    display: block;
    padding: 10px;
  }
}
</style>